import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Nav from './componement/shared/nav';
import Home from './componement/home';
import Commands from './componement/Commands';

class App extends React.Component {
  render() {
    return (
      <div className="app">
        <Router>
          <Nav />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/commands" component={Commands} />
          </Switch>
        </Router>
      </div>
    )
  }
}

export default App;