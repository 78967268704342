import React from 'react'
import { Container, Row, Spinner, Col, Nav, Navbar } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'

class Commands extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            items: [],
        }
    }
    componentDidMount() {
        fetch('/api/commands')
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({
                    items: data,
                    isLoading: false
                })
            })
            .catch(e => console.log(e))
    }
    contextRef = React.createRef();
    render() {
        return (
            <Container fluid="auto" id="commands">
                <title>Hanekawa - Commands</title>
                {this.state.isLoading
                    ? <Spinner animation='border' role="status" style={{ width: "100px", height: "100px", marginLeft: "50%", marginTop: "50px" }} />
                    : <Row>
                            <Col md={2}>
                                    <Navbar variant="dark" bg="dark" sticky="top">
                                    <Nav className="flex-column" variant="pills" bg="dark">
                                        {this.state.items.map(e => <Nav.Item><Nav.Link href={`#${e.name}`}>{e.name}</Nav.Link></Nav.Item>)}
                                    </Nav>
                                    </Navbar>
                            </Col>
                        <Col>
                            {this.state.items.map(e =>
                                <section id={e.name}>
                                    <h2>{e.name}</h2>
                                    <p>{e.description}</p>
                                    <Table responsive="auto" striped hover borderless="true" variant="dark">
                                        <thead>
                                            <th><h6><b>Name</b></h6></th>
                                            <th><h6><b>Command</b></h6></th>
                                            <th><h6><b>Description</b></h6></th>
                                            <th><h6><b>Example</b></h6></th>
                                            <th><h6><b>Permission</b></h6></th>
                                        </thead>
                                        <tbody>
                                            {e.commands.map(c =>
                                                <tr>
                                                    <td>{c.name}</td>
                                                    <td>{c.commands.map(n => <p style={{ margin: 0, padding: 0 }}>h.{n}</p>)}</td>
                                                    <td>{c.description}</td>
                                                    <td>{c.example.map(n => <p style={{ margin: 0, padding: 0 }}>h.{n}</p>)}</td>
                                                    <td>{c.permissions ?? "None"}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </section>)}
                        </Col>
                    </Row>}
            </Container>
        )
    }
}

export default Commands;