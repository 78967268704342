import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Nav extends React.Component {
    render() {
        return (
            <div>
                <Navbar expand="lg" variant="dark">
                    <div className="container">
                        <Navbar.Brand><Link to="/" style={{color: "#fff"}}>haneKawa</Link></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" >
                            <Link className="nav-item nav-link" to="/" style={{color: "#fff"}}>Home</Link>
                            {/* <Link className="nav-item nav-link" to="/about">About</Link> */}
                            <Link className="nav-item nav-link" to="/commands" style={{color: "#fff"}}>Commands</Link>
                            <ul className="navbar-nav ml-auto nav-flex-icons">
                                <li className="nav-item">
                                    <a className="nav-link waves-effect waves-ripple" href="http://github.com/sphexator" target="_blank" rel="noreferrer">
                                        <i className="fab fa-github fa-2x" />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link waves-effect waves-ripple" href="https://discord.gg/gGu5TT6" target="_blank" rel="noreferrer">
                                        <i className="fab fa-discord fa-2x" />
                                    </a>
                                </li>
                            </ul>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </div>
        )
    }
}

export default Nav;