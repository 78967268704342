import React from 'react';

class Home extends React.Component {
  render() {
    return (
      <div class="d-flex position-relative justify-content-center align-items-center w-100 h-100 content-area" style={{marginTop: "10%"}}>
        <div id="base" class="column mr-4">
          <title>Hanekawa</title>
          <div className="title-header my-0">Hanekawa</div>
          <hr className="hr-light mt-0" />
          <p>Hanekawa is a multi-purpose and stable Discord Bot written in C# for Fun, Moderation, User Profiles,
            Games, Music and much more!</p>
          <div id="btns" className="d-flex">
            <a className="btn btn-dark" href="http://github.com/sphexator" target="_blank" rel="noreferrer"><i
              className="fab fa-github mr-1"></i> Github</a>
            <a className="btn btn-primary content-button"
              href="https://discord.com/api/oauth2/authorize?client_id=431610594290827267&permissions=8&scope=bot%20applications.commands" target="_blank" rel="noreferrer">
              <i className="fas fa-plus mr-1"></i> Invite to Server</a>
            <a className="btn btn-primary content-button" href="https://discord.gg/gGu5TT6" target="_blank" rel="noreferrer">
              <i className="fab fa-discord mr-1"></i> Discord Server</a>
          </div>
        </div>
        <img id="render" src="assets/img/haneRender.png" alt="" height="300" />
      </div>
    )
  }
}

export default Home;